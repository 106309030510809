/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/macy@2.3.0/dist/macy.min.js
 * - /npm/slideout@1.0.1/dist/slideout.min.js
 * - /npm/lazysizes@4.1.0/plugins/unveilhooks/ls.unveilhooks.min.js
 * - /npm/lazysizes@4.1.0/lazysizes.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
